import React from 'react'
import Homepage from '../pages/Homepage'

const AppBody = () => {
  return (
    <div>
      <Homepage />
    </div>
  )
}

export default AppBody
