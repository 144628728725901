import React from 'react'
import AppBody from '../layout/AppBody'

const AppStruct = () => {
  return (
    <div>
      <AppBody />
    </div>
  )
}

export default AppStruct
